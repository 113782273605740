const {Dexie} = require('dexie')
// const PouchDB = require('pouchdb').default
// const GinkgoToApi = require('./GinkgoToApi')
const axios = require('axios')
const LocalStorageSharding = require("@/utils/LocalStorageSharding");

axios.defaults.maxSockets = 10;

class DBAccess {
    static initSchemes = {
        log: '++id, type',
        rightApps: 'uid',
        friends: 'id, snsId'
    }

    static dbSchemes = {}
    static db = undefined
    static dbName = 'GinkgoTo'
    static version = 1

    static GPT_AUTH_KEY = process.env.VUE_APP_GAK
    static GPT_BD_KEY = process.env.VUE_APP_BD_KEY
    static GPT_AUTH_INFO_KEY = 'GPT_AUTH_INFO'

    static WX_WORK_INFO = 'wxWorkInfo'
    static GPT_MSG_KEY = 'gpt_msg_sharding'

    static GPT_MSG_SHARDING = new LocalStorageSharding(50, DBAccess.GPT_MSG_KEY)

    static async changeSchema(db, schemaChanges) {
        db.close();
        const newDb = new Dexie(db.name);

        newDb.on('blocked', () => false); // Silence console warning of blocked event.

        // Workaround: If DB is empty from tables, it needs to be recreated
        if (db.tables.length === 0) {
            await db.delete();
            newDb.version(1).stores(schemaChanges);
            return await newDb.open();
        }

        // Extract current schema in dexie format:
        const currentSchema = db.tables.reduce((result, {name, schema}) => {
            result[name] = [
                schema.primKey.src,
                ...schema.indexes.map(idx => idx.src)
            ].join(',');
            return result;
        }, {});

        console.log("Version: " + db.verno);

        // Tell Dexie about current schema:
        newDb.version(db.verno).stores(currentSchema);
        // Tell Dexie about next schema:
        newDb.version(db.verno + 1).stores(schemaChanges);

        console.log("Current Schema: ", currentSchema, schemaChanges);
        // Upgrade it:
        return await newDb.open();
    }

    /**
     * 自动添加scheme后，保存数据
     * @param key
     * @param value
     * @returns {Promise<void>}
     */
    static async updateIndexDB(key, value) {
        const _key = key + ''
        if (!DBAccess.db) {
            DBAccess.db = new Dexie(DBAccess.dbName)
            if (!(await Dexie.exists(DBAccess.db.name))) {
                console.log("Db does not exist");
                DBAccess.db.version(1).stores(DBAccess.dbSchemes);
            }
            await DBAccess.db.open();
            console.log("Could open DB")
        }

        let scheme = DBAccess.dbSchemes[_key]

        if (!scheme) {
            scheme = '++g_id'
            DBAccess.dbSchemes[_key] = DBAccess.initSchemes[_key] || scheme
            DBAccess.db = await DBAccess.changeSchema(DBAccess.db, DBAccess.dbSchemes)
        }

        if (DBAccess.db[_key]) {
            if (value instanceof Object) {
                DBAccess.db[_key].put(Dexie.deepClone(value))
            }
        }
    }

    static log(msg) {
        if (msg) {
            //
        }
        // console.log('收到消息存储到日志表中', log)
        // DBAccess.updateIndexDB('log', log).catch(e => {
        //   console.log(e)
        // })
    }

    // static saveIndexDB(key, value) {
    //   let db = DBAccess.dbSchemes[key]
    //   if (!db) {
    //     db = new PouchDB(key + '');
    //     DBAccess.dbSchemes[key] = db
    //   }
    //   if (value instanceof Array) {
    //     for (const i in value) {
    //       db.put(value[i], () => {
    //
    //       })
    //     }
    //   } else {
    //     for (const o in Object.values(value)) {
    //       db.put(o, () => {
    //
    //       })
    //     }
    //   }
    // }

    static setItem(key, value) {
        if (key) {
            if (this.triggers[key]) {
                this.triggers[key].set(key, value)
                if (this.triggers[key].abort) {
                    return
                }
            } else {
                for (const trigger of Object.values(this.triggers)) {
                    if (trigger.matches(key)) {
                        trigger.set(key, value)
                        if (trigger.abort) {
                            return
                        } else {
                            break
                        }
                    }
                }
            }

            if (value instanceof Object) {
                localStorage.setItem(key, JSON.stringify(value))
                // DBAccess.saveIndexDB(key, value)
                // DBAccess.updateIndexDB(key, value).then(r => {})
            } else {
                localStorage.setItem(key, value)
            }
        }
    }

    static getItem(key, defaultReturnValue) {
        if (key) {
            const _strValue = localStorage.getItem(key)
            if (_strValue) {
                try {
                    return JSON.parse(_strValue)
                } catch (e) {
                    return _strValue
                }
            }
        }
        return defaultReturnValue;
    }

    static removeItem(key) {
        if (key) {
            localStorage.removeItem(key)
        }
    }

    static upsertCustomSetting(settings, cb) {
        if (window.ginkgoTo?.upsertCustomSettings && settings) {
            if (window.ginkgoTo.VERSION) {
                // console.log('同步设置信息到数据库', settings)
                window.ginkgoTo['upsertCustomSettings'](JSON.stringify(settings), cb)
            }
            // console.log()
            // GinkgoToApi.call('upsertCustomSettings', {...settings}, cb)
        }
    }

    // static queryCustomSetting(userId, phone, from) {
    //   // 从前端本地缓存里取，登录后会在用户信息里
    // }

    static getWxUserInfo(uid, pcUid, tokenCode) {
        // uid 指定用户id （pc），  pcUid this.$root.uid，tokenCode 扫码后的id
        return new Promise((resolve, reject) => {
            axios.get('/yll/wechat/gzh/isAuth?id=' + (uid || pcUid || '') + '&uid=' + (tokenCode || '') + '&key=' + DBAccess.GPT_AUTH_KEY).then(res => {
                if (res && res.data) {
                    const gptAuthInfo = {
                        isAuth: true,
                        gptUid: res.data._id,
                        timestamp: new Date().getTime(),
                        wxAuthInfo: res.data
                    }
                    this.setItem(DBAccess.GPT_AUTH_INFO_KEY, {...gptAuthInfo})
                    resolve({msg: '登录成功', gptAuthInfo})
                } else {
                    reject({error: 'User not found!', msg: '登录失败，没有该用户'})
                }
            }).catch(err => {
                console.error('err-->', err)
                reject(err)
            })
        })

    }

    static getGptTokenBalance(gptUid, consume, view) {
        // 每当登录成功后会调用该方法一次！
        // console.log('[debug:进入了balance]', consume)
        if (gptUid) {

            let data = JSON.stringify({
                "orderType": 4,
                "uid": gptUid,
                "usedNum": consume || 0
            })
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: '/yll/testwx/gptRestNum',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            }

            axios.request(config).then(res => {
                // console.log('getGptTokenBalance--->', res.data)
                view.gptAuthInfo.balance = {
                    total: res?.data?.data?.totalQuantity || 0,
                    used: res?.data?.data?.usedNiche || res?.data?.data?.usedNum || 0,
                    icon: res?.data?.data?.icon || 0
                }
                // this.openId = res?.data?.data?.openid
                this.setItem(this.GPT_AUTH_INFO_KEY, {...view.gptAuthInfo})

            }).catch(error => {
                console.error('获取GPT TOKEN--err-->', error)
            })
        } else {
            console.log('授权的uid为空')
        }

    }

    static axiosGet(req) {
        return axios.get(req)
    }

    static gptApps() {
        return this.axiosGet('https://www.autoinfotech.cn/autoinfotech/cmsapii/ruyiApps')
    }

    static executeFunction(f, ...args) {
        if ('function' === typeof f) {
            return f(...args)
        }
    }

    static streamRequestByAxios(source, path, payload, handler) {
        if (handler) {
            const {onOpen, onError, onMessage, onStop} = handler
            DBAccess.executeFunction(onOpen)
            axios({
                method: 'post',
                url: '/api',
                // responseType: 'stream',
                data: {
                    "source": source,
                    "path": path,
                    "payload": payload
                },
                onDownloadProgress: e => {
                    // console.log('onDownloadProgress:', e)
                    DBAccess.executeFunction(onMessage, e.target?.responseText || '')
                }
            }).then(function (response) {
                // console.log('response.data.pipe', response)
                DBAccess.executeFunction(onStop, response.data)
            }).catch(error => DBAccess.executeFunction(onError, error));
        }
    }


    static streamRequestTXHY(payload, handler) {
        const t = Math.floor(new Date().getTime() / 1000)
        const et = t + (20 * 60)
        DBAccess.streamRequestByAxios('txhy', '/chat/completions',
            {...payload,
                "expired": et,
                "temperature": 1,
                "timestamp": t,
                "top_p": 0.8}, handler)
    }


    static gptBaiduReferCount(gptUid, createTime) {
        const _createTime = Math.ceil(new Date(new Date().toLocaleDateString()).getTime() / 1000).toString(16) + '0000000000000000'
        return axios.post('/yll/wechat/gzh/queryDataInfo', {
            "__key": DBAccess.getAuthKeyObject().__keys,
            "__createTime": _createTime,
            "size": 10,
            "page": 0,
            "id": gptUid,
            "tableName": "wx_login_user_info",
            "sortKey": "createTime", "sortDesc": -1,
            "aggId": "648fc405a3739d6ae6549e37"
        }, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            }
        })
    }

    static gptDashboardReq(gptUid, year, month, day) {
        // wechat/gzh/total?key=auto_gzh_chat,ruyi_h5_gpt_home&id=64476dd71211c6324e7a6c89
        return this.axiosGet(`/yll/wechat/gzh/total?key=${this.getAuthKeyObject().all}&id=${gptUid}${year ? '&year=' + year : ''}${month ? '&month=' + month : ''}${day ? '&day=' + day : ''}`)
    }

    static gptOrderList(gptUid, page, size) {
        // wechat/gzh/queryOrderList?size=10&key=ruyi_h5_gpt_home&id=64476bbd1211c6324e7a2c80
        return this.axiosGet(`/yll/wechat/gzh/queryOrderList?size=${size || 300}&id=${gptUid}`)
    }

    static gptEmployeeRequest(gptUid, roles) {
        // wechat/gzh/queryRolesShareInfo?roles=employee&key=auto_gzh_chat,ruyi_h5_gpt_home&id=64476dd71211c6324e7a6c89&sortKey=_id&page=0&size=100
        return this.axiosGet(`/yll/wechat/gzh/queryRolesShareInfo?roles=${roles || 'employee'}&key=${this.getAuthKeyObject().all}&id=${gptUid}&sortKey=_id&page=0&size=100`)
    }

    static batchGetRequest(promise, handles) {
        Promise.all(promise).then((resp) => {
            if (Array.isArray(resp) && Array.isArray(handles)) {
                for (let r = 0; r < resp.length; r++) {
                    if ('function' === typeof handles[r]) {
                        // console.log('调用回调函数...', handles[r])
                        handles[r](resp[r])
                    }
                }
            }
        }).catch(error => {
            console.error(error)
        })
    }

    static batchGptUsersRequest(params) {
        if (Array.isArray(params)) {
            const promise = []
            for (let i = 0; i < params.length; i++) {
                const {gptUid, key, sortKey, page, size, startTime, endTime, roles} = params[i]
                promise.push(axios.get(`/yll/wechat/gzh/queryUserInfo?key=${key}&sortKey=${sortKey || '_id'}&startTime=${startTime || 0}`
                    + `&id=${gptUid}${endTime ? '&endTime=' + endTime : ''}&page=${page || 0}&size=${size || 10}${roles ? '&roles=' + roles : ''}`))
            }

            // this.batchGetRequest(promise, handles)
            return promise
        }
    }

    static batchDailyReport(size, key, gptUid) {
        // yll/wechat/gzh/totalInfo?size=8&key=ruyi_h5_gpt_home&id=64476bbd1211c6324e7a2c80
        return axios.get(`/yll/wechat/gzh/totalInfo?size=${size}&key=${key}&id=${gptUid}`)
    }

    static getGptUserDetails(gptUid, searchId) {
        // wechat/gzh/detailUserInfo?key=zhyc_gzh_chat&id=64423d9a1211c6324e23fa90&searchId=644550a31211c6324e55b31a
        return axios.get(`/yll/wechat/gzh/detailUserInfo?id=${gptUid}&searchId=${searchId}`)
    }

    static getGptUserRewards(gptUid, searchId, page, size) {
        // {{yll}}/wechat/gzh/queryAddLog?id=64423d9a1211c6324e23fa90&searchId=64474a141211c6324e76f7ad&page=0&size=10
        return axios.get(`/yll/wechat/gzh/queryAddLog?id=${gptUid}&searchId=${searchId}&page=${page || '0'}&size=${size || 10}`)
    }


    static getGptAgenciesByUid(gptUid, searchId, page, size) {
        // yll/wechat/gzh/queryShareInfo?id=64423d9a1211c6324e23fa90&searchId=644645851211c6324e693ec8&page=0
        return axios.get(`/yll/wechat/gzh/queryShareInfo?id=${gptUid}&searchId=${searchId}&page=${page || '0'}&size=${size || 10}`)
    }

    static genDallEImage(gptUid, prompt, num, size) {
        // https://zpi.zhycit.com/yll/openAI/createImage
        const _size = size || '512'
        return axios.post('/yll/openAI/createImage', {
            uid: gptUid,
            path: 'images/generations',
            param: {
                "model": "image-alpha-001",
                "prompt": prompt,
                "num_images": num || 1,
                "size": _size + 'x' + _size,
                "response_format": "url"
            }
        }, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            }
        })
    }

    static qwAIChat(msg) {
        // console.log('进来了吗？')
        return axios.post('/yll/aliyun/api', {
            path: 'text-generation/generation',
            name: DBAccess.GPT_BD_KEY, // 百度api对应的key
            param: {
                model: "qwen-v1",
                input: {
                    prompt: msg
                }
            }
        }, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            }
        })
    }


    static baiduAIChat(messages) {
        // console.log('进来了吗？')
        return axios.post('/yll/bdAi/api', {
            path: 'chat/eb-instant',
            name: DBAccess.GPT_BD_KEY, // 百度api对应的key
            param: {
                messages: messages
            }
        }, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            }
        })
    }

    static openAITran(gptUid, prompt, lang) {
        return axios.post('/yll/openAI/createImage', {
            uid: gptUid,
            path: 'completions',
            param: {
                "model": "text-davinci-003",
                "prompt": `translate to ${lang || 'English'}: ${prompt}`,
                "temperature": 0,
                "max_tokens": prompt.length * 10,
                "top_p": 1.0,
                "frequency_penalty": 0.0,
                "presence_penalty": 0.0
            }
        }, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            }
        })
    }

    static getGptUsers(gptUid, key, sortKey, page, size, startTime, endTime, name, roles, desc) {
        return new Promise((resolve, reject) => {
            axios.get(`/yll/wechat/gzh/queryUserInfo?key=${key}&sortKey=${sortKey || '_id'}&desc=${desc}&startTime=${startTime || 0}`
                + `&id=${gptUid}${endTime ? '&endTime=' + endTime : ''}&page=${page || 0}&size=${size || 10}&name=${name || ''}&roles=${roles || ''}`).then(res => {
                // console.log('getGptTokenBalance--->', res.data)
                // console.log(res)
                resolve(res)
            }).catch(error => {
                console.error('获取GPT TOKEN--err-->', error)
                reject(error)
            })

        })
    }

    static getGptAgencyList(gptUid, key, sortKey, page, size) {
        return new Promise((resolve, reject) => {
            // https://api.zhycit.com/yll/wechat/gzh/queryUserShareInfo?key=zhyc_share&id=64423d9a1211c6324e23fa90
            axios.get(`/yll/wechat/gzh/queryUserShareInfo?key=${key}&id=${gptUid}&sortKey=${sortKey || '_id'}`
                + `&page=${page || 0}&size=${size || 10}`).then(res => {
                // console.log('getGptTokenBalance--->', res.data)
                console.log(res)
                resolve(res)
            }).catch(error => {
                console.error('获取GPT TOKEN--err-->', error)
                reject(error)
            })

        })
    }

    static getAuthKeyObject() {
        // console.log('process.env:', process.env)
        const authKey = {
            pc: DBAccess.GPT_AUTH_KEY
        }

        const _prefix = authKey.pc?.split('_')[0]
        let prefix = 'ruyi' === _prefix ? 'auto' : _prefix
        authKey.gzh = prefix + '_gzh_chat'

        const _all = [authKey.gzh, authKey.pc,
            prefix + "_gzh_pay",
            prefix + "_share",
            prefix + "_gzh_mine"]
        return {...authKey, all: _all.join(','), __keys: _all.map(item => `'${item}'`).join(',')}
    }

    static GPT_CHANNELS = {}

    static pushGptChannelInstance(key, instance) {
        DBAccess.GPT_CHANNELS[key] = instance
    }

    static addGptChannel(channel) {
        for (const key in DBAccess.GPT_CHANNELS) {
            DBAccess.GPT_CHANNELS[key].gptChannels.push(channel)
        }
    }

    static updateAllGptChannel(channel) {
        for (const key in DBAccess.GPT_CHANNELS) {
            DBAccess.GPT_CHANNELS[key].updateChannel(channel)
        }
    }

    static triggers = {
        'preferences': {
            set(key, value) {
                for (let settings of Object.values(value)) {
                    DBAccess.upsertCustomSetting(settings, resp => {
                        console.log(resp)
                    })
                }
            },
            matches: (key) => 'preferences' === key,
            abort: false
        },
        'rightApps_': {
            set(key, value) {
                DBAccess.upsertCustomSetting({
                    rightApps: value,
                    userId: key.substring('rightApps_'.length)
                })
            },
            matches: (key) => key?.startsWith('rightApps_'),
            abort: false
        },
        'friends_': {
            set(key, value) {
                if (value) {
                    const snsId = key.substring('friends_'.length)
                    for (const friend of Object.values(value)) {
                        console.log('添加indexed', friend)
                        DBAccess.updateIndexDB('friends', {snsId, ...friend}).then(f => {
                            console.log('更新了好有列表', f)
                        }).catch(err => {
                            console.error('更新了好有列表异常', err)
                        })
                    }
                }

            },
            // get(key) {},
            matches: (key) => key?.startsWith('friends_') && false,
            abort: false
        }
    }

    static ydName = [
        {'numId': 1, 'name': '火花塞'},
        {'numId': 2, 'name': '点火线圈'},
        {'numId': 3, 'name': '刹车片'},
        {'numId': 4, 'name': '刹车盘'},
        {'numId': 5, 'name': '机油'},
        {'numId': 6, 'name': '自动档变速箱油ATF'},
        {'numId': 7, 'name': '手动变速箱油MTF'},
        {'numId': 8, 'name': '差速器油'},
        {'numId': 9, 'name': '转向助力油'},
        {'numId': 10, 'name': '机油滤清器'},
        {'numId': 11, 'name': '空气滤清器'},
        {'numId': 12, 'name': '空调滤清器'},
        {'numId': 13, 'name': '燃油滤清器'},
        {'numId': 14, 'name': '雨刷'},
        {'numId': 15, 'name': '蓄电池'},
        {'numId': 16, 'name': '高压阻尼线'},
        {'numId': 17, 'name': '制动液'},
        {'numId': 18, 'name': '防冻液'},
        {'numId': 19, 'name': '冷媒'},
        {'numId': 20, 'name': '感应线'},
        {'numId': 21, 'name': '正时带'},
        {'numId': 22, 'name': '添加剂'},
        {'numId': 23, 'name': '刹车蹄'},
        {'numId': 24, 'name': '滤清器'},
    ]
    static ydNames = ['火花塞', '点火线圈', '刹车片', '刹车盘', '机油', '自动档变速箱油ATF', '手动变速箱油MTF', '差速器油', '转向助力油', '机油滤清器', '空气滤清器', '空调滤清器', '燃油滤清器', '雨刷', '蓄电池', '高压阻尼线', '制动液', '防冻液', '冷媒', '感应线', '正时带', '添加剂', '刹车蹄', '滤清器']

    static getSortList(products) {
        let pList = [];
        for (let key in products) {
            let obj = {};
            obj.name = key
            obj.ids = products[key];
            pList.push(obj)
        }
        pList.sort((a, b) => {
            return this.ydNames.indexOf(a.name) - this.ydNames.indexOf(b.name);
        })
        return pList;
    }


}

module.exports = DBAccess

