<template>
  <div class="card-active  m-auto ginkgoto-sns-bind">
    <div v-if="loading" class="page-loading active"
         style="width:16%;height:24%;top: 36%;left: 42%;background-color: #ffffff">
      <div class="page-loading-inner">
        <div class="page-spinner"></div>
        <div>微信启动中...</div>
      </div>
    </div>
    <div v-else>
      <div class="card-body p-2" v-if="!isShowProducts">
        <div class="fs-xs text-warning text-center">
          微信不支持window7操作系统，请在window10及以上系统中运行
        </div>
        <div id="snsLoginFrame" :class="'my-2 m-auto text-center rounded border-secondary '"
             :style="'width: 6rem; height: 6rem; overflow: hidden;background:url(' + this.loginBgImg + ') no-repeat center; background-size: cover;'">
        </div>
        <div class="card-footer p-2 border-0">
          <div class="input-group">
            <input class="form-control form-control-light" type="text" placeholder="客户端IP"
                   style="width:70%;"
                   v-model="remoteHost">
            <input class="form-control form-control-light" type="number" maxlength="5"
                   style="width:30%;"
                   placeholder="端口" v-model="remotePort">
          </div>

          <div class="border-light d-flex align-items-center justify-content-center pt-2 pb-2 gap-2 mt-5">
            <button v-show="!powerBtnLoading" type="button" class="btn btn-outline-primary btn-sm"
                    v-on:click="startWxp">
              <i :class="powerBtnSts.icon + ' me-2'"></i>{{ powerBtnSts.text }}
            </button>
            <button v-show="powerBtnLoading" type="button" class="btn btn-secondary btn-sm">
              <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
              登录中...
            </button>
            <button type="button" class="btn btn-outline-success btn-sm" @click="startRemoteWxp">远程启动</button>
            <button type="button" class="btn btn-outline-light btn-sm"
                    data-bs-dismiss="modal"
                    aria-label="Close">关闭
            </button>
          </div>

          <div class="border-light text-center pt-2 pb-2 gap-2 mt-5">
            <button type="button" class="btn btn-outline-success " @click="activationPlugin">第三方插件入口</button>
            <p v-show="wxAccount > 0" class="mt-3 text-light">目前你可以登录微信的个数是：{{ wxAccount }}</p>
          </div>
        </div>
      </div>
      <!-- 展示商品列表 -->
      <div class="card-body p-2" v-else>
        <div class="p-1">
          <div class="mb-2" v-for="(item,index) in products" :key="index">
            <div class="card  border-0 bg-faded-info">
              <div class="card-body text-center">
                <div class="d-flex align-items-end justify-content-center mt-4 mb-3">
                  <div class="h1 mb-0 text-danger">¥{{ item.price }}</div>
                  <div class="pb-2 ps-2 text-light">/{{ item.unit }}</div>
                </div>
                <div>
                  <del class="text-light">原价:{{ item.marketPrice }}元</del>
                </div>
                <a class="btn btn-outline-primary rounded-pill mt-4" style="min-width: 120px"
                   @click="buyNow(item.productId)">立即购买</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--支付二维码弹框-->
    <el-dialog v-model="showPayDialog" :title="productTitle" @close="closeDialog"
               :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="true">
      <div class="col tns-item tns-slide-active">
        <div class="border-0 h-100 pb-2 pb-sm-3 px-sm-3 text-center ">
          <img class="d-block mx-auto"
               :src="imgUrl" width="200" alt="支付二维码">
          <div class="card-body">
            <h4 class="h4 card-title">微信扫码付款,总金额: <span class="text-danger">{{ totalPrice }} </span>元</h4>
            <h6 class="h6 card-title text-success">{{ paySuccess }}</h6>
            <p class="card-text fs-sm">扫码代激活<br>该插件激活序列号由第三方平台提供（https://wechaty.js.org/）</p>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import LogMsgHandler from "@/components/message/handlers/GlobalHandler";

export default {
  props: {
    uid: {
      type: String,
      required: true
    },
    sns: {
      type: Object,
      required: false,
    }
  },
  data: () => {
    return {
      wxName: 'gkt-wxp-01',
      remoteHost: '127.0.0.1',
      remotePort: 59033,
      loading: false,
      isOnline: false,
      loginBgImg: 'https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/wx_online.png',
      powerBtnLoading: false,
      powerBtnSts: {},
      powerBtnInfo: {
        started: {icon: 'fi-power', text: '退出', btn: 'btn-primary', status: true},
        stopped: {text: '登录', icon: 'fi-play', btn: 'btn-secondary', status: false}
      },
      isShowProducts: false,
      showPayDialog: false,
      products: [],
      productTitle: '',
      imgUrl: '',
      totalPrice: '',
      paySuccess: '',
      timers: [],
      wxAccount: 0
    }
  },
  created() {
    this.powerBtnSts = this.powerBtnInfo.stopped
    if (this.sns) {
      this.snsId = this.sns.snsId
    }
    this.wxName = this.sns?.wxName || ('wx-' + new Date().getTime())
    // console.log("snsId===00--->", this.snsId, this.wxName)
    this.getOrderList();
  },
  methods: {
    initMsgHandler(currentSnsId) {
      if ('function' === typeof window.ginkgoTo['pushMsgHandler']) {
        console.log('微信开启登录事件监听---')
        window.ginkgoTo['pushMsgHandler']('WxpLogin', msg => {
          // console.log("wxplogin---msg--->", JSON.stringify(msg))
          try {
            const {type, objData} = msg
            const {wxName} = objData
            if (type === 27 && currentSnsId === wxName) {
              this.loading = false
              this.isOnline = true
              return ElMessage({
                message: '登录成功',
                type: 'success'
              })
            }
            if (type === 10004 && currentSnsId === wxName) {
              this.isOnline = false
              return ElMessage({
                message: "账号已退出",
                type: 'success'
              })
            }
          } catch (e) {
            console.log('WxpLogin--err-->', e)
          }
        })
      }
    },
    startRemoteWxp() {
      this.startWxp('startRemoteWx')
    },
    startWxp(name) {
      this.loading = true
      this.initMsgHandler(this.remoteHost)
      console.log(`[BEBUG:WxpLogin/${name}] ${this.remoteHost}:${this.remotePort}`)
      LogMsgHandler.currentSnsId = this.remoteHost
      LogMsgHandler.addMsgHandle(this.remoteHost, this, (msg) => {
        if (msg) {
          console.log("msg-->", JSON.stringify(msg))
          if (msg?.msg.indexOf('server started') >= 0) {
            window.ginkgoTo.invoke('executeSnsApi', {
              uid: this.uid,
              snsId: this.remoteHost || this.snsId,
              method: 'login',
              snsType: 'WXP',
              args: {
                wxName: this.remoteHost || this.wxName,
                host: this.remoteHost,
                port: this.remotePort
              }
            })
          }
          if (msg?.msg.indexOf('Error: EBUSY: resource busy or locked') >= 0) {
            this.loading = false
            return ElMessage({
              message: '登录失败',
              type: 'error'
            })
          }
          if (msg?.msg.indexOf('WRP LOGOUT') >= 0 || msg?.msg.indexOf('Bot start() fail') >= 0) {
            this.loading = false
            return ElMessage({
              message: '登录失败',
              type: 'error'
            })
          }
          if (msg?.msg.indexOf('没有登录，或是微信版本不正确，3秒后重试') >= 0) {
            this.loading = false
            return ElMessage({
              message: '微信版本不正确或没有登录微信',
              type: 'error'
            })
          }
        }
      });
      window.ginkgoTo.invokeIpcMain(name || 'startWx', (event, msg) => {
        LogMsgHandler.mclMsgHandle(msg)
      });

      /*window.ginkgoTo.invokeIpcMain(name || 'startWx', (event, msg) => {
        if (msg) {
          console.log('host--->>>>', host)
          if (msg?.msg.indexOf('server started') >= 0) {
            window.ginkgoTo.invoke('executeSnsApi', {
              uid: this.uid,
              snsId: host || this.snsId,
              method: 'login',
              snsType: 'WXP',
              args: {
                wxName: this.remoteHost + this.remotePort || this.wxName,
                host: this.remoteHost,
                port: this.remotePort
              }
            })
          }
          if (msg?.msg.indexOf('Error: EBUSY: resource busy or locked') >= 0) {
            this.loading = false
            return ElMessage({
              message: '登录失败',
              type: 'error'
            })
          }
          if (msg?.msg.indexOf('WRP LOGOUT') >= 0 || msg?.msg.indexOf('Bot start() fail') >= 0) {
            this.loading = false
            return ElMessage({
              message: '登录失败',
              type: 'error'
            })
          }
          if (msg?.msg.indexOf('没有登录，或是微信版本不正确，3秒后重试') >= 0) {
            this.loading = false
            return ElMessage({
              message: '微信版本不正确或没有登录微信',
              type: 'error'
            })
          }
        }
      })*/
    },

    //----------检查用户是否有登录微信的资质---------------------
    //检查用户是否已购买，如果购买，可以登录，否则显示商品列表，进行购买。
    getLoginWxCertification() {

    },
    //商品列表 显示
    activationPlugin() {
      this.isShowProducts = true;
      this.getProductList();
    },
    //获取商品信息
    getProductList() {
      window.ginkgoTo.fetchProductList().then(result => {
        if (result !== null && result?.code === 200) {
          this.products = result.data;
        } else {
          console.log('数据为空')
        }
      })
    },
    clearTimers() {
      this.timers.forEach(tid => clearInterval(tid))
      this.timers.length = 0;
    },
    closeDialog() {
      this.showPayDialog = false;
      this.clearTimers();
    },

    //购买--显示二维码
    buyNow(productId) {
      let userInfo = this.$root.user;
      this.showPayDialog = true;
      window.ginkgoTo.fetchProductWxPayQrcodeInfo(productId, this.uid, userInfo.phone).then(result => {
        if (result !== null && result.data !== null) {
          console.log('result===>', result.data)
          let info = result.data;
          this.productTitle = info.goodsName
          this.imgUrl = "/yll/hooparts/wxpay/qrcode?width=180&height=180&urlCode=" + info.urlCode;
          this.totalPrice = info.totalPrice;
          //开始查询订单信息
          this.getOrderInfo(info.orderId);
        } else {
          console.error('获取二维码失败')
        }
      }).catch(e => {
        console.error('e-->', e)
      })
    },
    //获取某个订单信息
    getOrderInfo(orderId) {
      this.timers.push(setInterval(() => {
        window.ginkgoTo.fetchOrderInfo(orderId).then(result => {
          if (result !== null) {
            if (result?.payState === 1) {
              this.paySuccess = "支付成功"
              this.clearTimers();
              this.showPayDialog = false;
              setTimeout(() => this.closeDialog(), 1000)
              this.getOrderList()
            }
          }
        }).catch(e => {
          console.error("e:", e)
        })
      }, 5000))
    },

    //获取购买的订单信息，来判断是否可以登录--微信-及登录微信的个数
    getOrderList() {
      let userInfo = this.$root.user;
      window.ginkgoTo.fetchOrderList(userInfo.userId).then(result => {
        this.isShowProducts = !(result !== null && result.length > 0);
        this.wxAccount = result.length;
        this.getProductList();
      }).catch(error => {
        console.error('订单信息List-error--', error)
      })
    }

    //-------------------------------------------------------

  }

}

</script>

<style scoped>
.ginkgoto-sns-bind {
  width: 23rem;
  height: 30rem;
}
</style>
