// const moment = require('moment')

export default {
  namespaces: true,
  state: {
    snsMap: {}
  },
  getters: {
    online: state => snsId => {
      if (snsId) {
        const sns = state.snsMap[snsId + '']
        if (sns['isOnline']) {
          return true
        }
      }
      return false
    },
    snsList: state => {
      let snsList = []
      for (const key in state.snsMap) {
        snsList.push(state.snsMap[key])
      }
      return snsList
    },
    fetchSns: state => snsId => {
      return state.snsMap[snsId + '']
    },
    filterSns: state => snsKeys => {
      let ret = []
      if (Array.isArray(snsKeys)) {
        for (let i = 0; i < snsKeys.length; i++) {
          const sns = state.snsMap[snsKeys[i]]
          if (sns) {
            ret.push(sns)
          }
        }
      }
      return ret
    }
  },
  mutations: {
    initStatus(state, online) {
      for (const key in state.snsMap) {
        // console.log(state.snsMap[key].name + 'set online: ', online)
        state.snsMap[key].isOnline = online
      }
    },
    upsertSns(state, sns) {
      if (sns) {
        const {snsId} = sns
        if (snsId) {
          let ret = {}
          const _snsIdStr = snsId + ''
          const oldSns = state.snsMap[_snsIdStr]
          if (oldSns) {
            ret = {...oldSns}
          }
          ret =  {...ret, ...sns}
          state.snsMap[_snsIdStr] = ret
        }
      }
    },
    deleteSns(state, snsId) {
      if (snsId) {
        const _snsIdStr = snsId + ''
        state.snsMap[_snsIdStr] = undefined
        console.log('删除了snsId：', snsId)
      }
    }
  },
  actions: {
  }
}
