<template>
  <div class="card-active m-auto ginkgoto-sns-bind">
    <div class="card-body p-1">
      <!--      <div id="snsLoginFrame" :class="'my-2 m-auto text-center rounded border-secondary ' + (isOnline?'': 'img-gray')"
                 :style="'width: 15rem; height: 15rem; overflow: hidden;background:url(' + this.loginBgImg + ') no-repeat center; background-size: cover;'">-->
      <div id="snsLoginFrame" :class="' m-auto text-center'" style="height: 255px">
        <el-image :class="(isOnline || !imgGray?'': 'img-gray')" :src="this.loginBgImg"
                  :style="'width: 15rem; height: 15rem; overflow: hidden; no-repeat center; background-size: cover;'"></el-image>
      </div>
    </div>
    <div class="card-footer p-1 px-2 border-0">
      <input class="form-control form-control-light mt-2" type="hidden" placeholder="用户名" v-model="snsId">
      <div class="border-light text-center pt-4 pb-3">
        <button v-show="!powerBtnLoading" type="button" class="btn btn-outline-primary btn-sm" style="width: 8.5rem"
                v-on:click="login">
          <i :class="powerBtnSts.icon + ' me-2'"></i>{{ powerBtnSts.text }}
        </button>
        <button v-show="powerBtnLoading" type="button" class="btn btn-secondary btn-sm" style="width: 8.5rem">
          <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
          Loading...
        </button>
        <button type="button" class="btn btn-outline-light btn-sm ms-2" style="width: 8.5rem"
                data-bs-dismiss="modal" aria-label="Close">关闭
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {ElMessage} from "element-plus";

export default {
  name: "ChatGptLogin",
  props: {
    uid: {
      type: String,
      required: true
    },
    sns: {
      type: Object,
      required: false,
    },
    snsDesc: {
      type: Object,
      required: false
    }
  },
  data: () => {
    return {
      snsId: '',
      isOnline: false,
      imgGray: false,
      loginBgImg: 'https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/ChatGPT.png',
      powerBtnSts: {},
      powerBtnInfo: {
        started: {icon: 'fi-power', text: '退出', btn: 'btn-primary', status: true},
        stopped: {text: '登录', icon: 'fi-play', btn: 'btn-secondary', status: false}
      },
      powerBtnLoading: false
    }
  },
  methods: {
    start() {
      // 将用户登录过的snsId记录到vuex的store里，这里是全局的
      console.log('user????->', this.$root.user.userName, this.$root.user.userImage)
      if (this.$gkt) { // 用gkt来判断是不是electron的终端
        this.$gkt.executeSnsApi(this.uid, this.uid, 'CHAT_GPT', 'login', {
          snsId: this.uid,
          uid: this.uid,
          user: {
            name: this.$root.user.userName,
            snsId: this.uid,
            avatar: this.$root.user.userImage
          }
        })
      } else {
        // TODO：使用wss框架
      }
    },
    stop() {
      if (this.gkt) {
        this.$gkt.executeSnsApi(this.uid, this.uid, 'CHAT_GPT', 'logout', {})
      }
    },
    login() {
      if (this.isOnline) {
        this.stop()
      } else {
        this.start()
      }
    },

    //计算消耗的tokens
    countGptBalanceNum(consume) {
      const gptAuthInfo = this.$dba.getItem('GPT_AUTH_INFO', {})
      const {gptUid} = gptAuthInfo
      if (gptUid) {
        let data = JSON.stringify({
          "orderType": 4,
          "uid": gptUid,
          "usedNum": consume || 0
        })
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: '/yll/testwx/gptRestNum',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        }

        axios.request(config).then(res => {
          console.log('getGptTokenBalance--->', res.data)
          gptAuthInfo.balance = {
            total: res?.data?.data?.totalQuantity || 0,
            used: res?.data?.data?.usedNiche || 0
          }
          console.log('gptAuthInfo--->', gptAuthInfo)
          this.$dba.setItem('GPT_AUTH_INFO', {...gptAuthInfo})
        }).catch(error => {
          console.error('获取GPT TOKEN--err-->', error)
        })
      } else {
        console.log('授权的uid为空')
      }
    }

  },
  mounted() {
    this.$gkt.call('pushMsgHandler', 'ChatGPTMsg', msg => {
      // 发送的消息，由puppet-gpt转回来

      const {type, objData} = msg
      if (27 === type) {
        this.isOnline = true
      }
      if (window.ginkgoTo?.MsgType['SEND_CHAT_GPT_MSG'].code === type) {
        // this.$root.aiChat?.componentId = 'gpt_' + objData.id
        this.$root.setAiMsgContent('gpt_' + objData.id)
        this.$root.setAiMessageHandler((msg) => {
          document.getElementById('roomTextarea').disabled = false
          // 页面重置这个方法
          document.getElementById('roomTextarea').classList.toggle('vac-textarea-ai-processing', false)
          const cursorAi = document.getElementsByClassName('cursor-ai')
          if (cursorAi) {
            cursorAi.forEach(e => {
              // window.getComputedStyle(e, ':before');
              e.remove()
            })
          }
          // 直接从消息队列里获取最后一条信息，如果消息ID和传入的id一样，则更新历史记录
          const cacheKey = 'msg_' + this.$root.uid + '_private_friend_' + objData?.senderId
          console.log(`MsgCacheKEY[${cacheKey}], msgId = ${objData.id}`)
          const _msgHistory = this.$dba.getItem(cacheKey, [])
          const popE = _msgHistory?.pop()
          if (objData?.id === popE?.id) {
            popE.payload.text = msg
            _msgHistory.push({
              ...popE, isGptReply: true, replyMessage: {
                content: objData.text,
                date: popE.date,
                username: popE.username,
                timestamp: popE.timestamp,
              }
            })
            this.$dba.setItem(cacheKey, _msgHistory)
          }
          // 计算使用的tokens
          const counts = this.$root.aiChat.messages
          let total = 0;
          counts.forEach(function (item) {
            total += item.content.length
          })
          console.log('total-->', total, msg?.length)
          if (total > 0) {
            this.countGptBalanceNum(total)
          }
        })
        this.$root.setAiTokenHandler(() => {
          document.getElementById('roomTextarea').disabled = true
          document.getElementById('messages-list').scrollTop =
              document.getElementById('messages-list').scrollHeight
          document.getElementById('roomTextarea').classList.toggle('vac-textarea-ai-processing')
        })
        setTimeout(() => {
          const {total, used} = this.$dba.getItem('GPT_AUTH_INFO', {})?.balance || {total: 0, used: 0}
          if (total - used > 0) {
            this.$root.requestAiMsg(objData.text)
          } else {
            return ElMessage({
              message: "你的余额已不足,请充值后再操作!",
              type: 'warning'
            })
          }
        }, 200)
      }
    })
  },
  created() {
    this.snsId = this.uid
    this.powerBtnSts = this.powerBtnInfo.stopped
    if (this.sns?.payload) {
      this.snsId = this.sns.snsId
      this.loginBgImg = this.sns.avatar
      this.imgGray = true
    }
    if (this.snsDesc) {
      this.loginBgImg = this.snsDesc.icon
    }
    // console.log('this.snsDesc', this.snsDesc)
  },
}
</script>

<style scoped>
.ginkgoto-sns-bind {
  width: 23rem;
  height: 30rem;
}
</style>
