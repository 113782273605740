class SnsMsgHandler {

    /**
     * 消息处理器
     *
     * 1、当有新的群或联系人里，将新的群或联系人添加到本地缓存
     * 2、当有新消息来时，将消息放入对应的消息桶中（按群或人分）
     * 3、当有新消息来时，如果消息发送着不是好友，则新建一个临时会话
     *
     * 注入页面：App
     *
     */
    static contactsListener(msg, view) {
        const that = view
        const MsgType = window.ginkgoTo.MsgType
        const {type, objData} = msg
        if (type && objData) {
            if (type === MsgType?.ON_CONTACTS.code) {
                // snsId，是当前登录账号的id
                console.log('type: ', type)
                const {snsId, friends} = objData
                const cacheKey = 'friends_' + snsId
                let _friends = that.$dba.getItem(cacheKey, {})
                for (const i in friends) {
                    const f = friends[i]
                    // console.log("f--->",f)
                    if (f.payload?.friend) {
                        _friends[f.id] = f
                    }
                }
                that.$dba.setItem(cacheKey, _friends)
                // localStorage.setItem(cacheKey, JSON.stringify(_friends))
            } else if (type === MsgType?.ON_ROOMS.code) {
                const {snsId, rooms} = objData
                console.log('type::: ', type)
                // console.log('objdata:', JSON.stringify(objData))
                const cacheKey = 'rooms_' + snsId
                let _rooms = that.$dba.getItem(cacheKey, {})
                for (const i in rooms) {
                    const r = rooms[i]
                    _rooms[r.id] = r
                }
                that.$dba.setItem(cacheKey, _rooms)
                // localStorage.setItem(cacheKey, JSON.stringify(_rooms))
            }
        }
    }

    /**
     * SNS账号上线/下线消息处理
     *
     * 注入页面：App
     *
     * @param msg
     * @param view
     */
    static snsOlineListener(msg, view) {
        const that = view
        // 只处理登录/登出
        try {
            const {objData, type} = msg
            if (objData && type) {
                if (27 === type || 0x2714 === type) {
                    const {snsId, snsType, user, wxName} = objData
                    if (snsId && user) {
                        // console.log("snsId:--->", snsId)
                        // console.log("user:--->", user)
                        let _sns = {...user, wxName: wxName}
                        // let oldSns = that.getSns(snsId)
                        let oldSns = that.$store.getters.fetchSns(snsId)
                        // console.log('oldSns::', JSON.stringify(oldSns))
                        if (oldSns) {
                            if (snsType === 'MQ') {
                                const snsPwd = oldSns.payload?.pwd || oldSns.snsPwd
                                _sns = {...oldSns, ..._sns, snsPwd: _sns?.snsPwd || snsPwd}
                                _sns.type = snsType
                                _sns.avatar = user.payload?.avatar
                                _sns.name = user.payload?.name
                                _sns.age = user.payload?.age
                                _sns.level = user.payload?.level
                                _sns.sex = user.payload?.sex
                                // TODO: 规范snsId, id, snsId是创建成功bot的唯一标识，也就是sns账号的原始id，而id可能是框架封装后的形式（openId...）
                                _sns.snsId = snsId
                            } else if (snsType === 'WXP') {
                                _sns = {..._sns, ...oldSns}
                                _sns.name = oldSns.payload.name
                                //console.log('oldSns-->', JSON.stringify(oldSns))
                            } else if (snsType === 'WX_GZH' || snsType === 'CHAT_GPT') {
                                _sns = {..._sns, ...oldSns}
                            }
                        } else {
                            _sns.type = snsType
                            _sns.avatar = user.payload?.avatar
                            _sns.name = user.payload?.name
                            _sns.age = user.payload?.age
                            _sns.level = user.payload?.level
                            _sns.sex = user.payload?.sex
                            // TODO: 规范snsId, id, snsId是创建成功bot的唯一标识，也就是sns账号的原始id，而id可能是框架封装后的形式（openId...）
                            _sns.snsId = snsId
                        }
                        // 登录成功一定要更新的值
                        _sns.isOnline = (27 === type)

                        // console.log("user: ", JSON.stringify(_sns))
                        // 做一下统一，payload里的是底层协议传上来的，直接用不通用
                        console.log(`${_sns.name || _sns.payload?.name} 登录成功`, _sns)
                        that.upsertSns(_sns) // 这是是app.vue里封装的
                        // that.$store.commit('upsertSns', _sns)
                        // console.log('that.uid-->', that.uid)
                        // 绑定SNS
                        that.$store.commit('bindSnsId', {userId: that.uid, snsId}) //('bindSnsId', that.uid, that.snsId)
                    }
                } else if (13100 === type || type === 13010) {
                    const {snsId, snsType, payload, isOnline} = objData
                    if (snsId && payload) {
                        let _sns = {...payload}
                        // let oldSns = that.getSns(id)
                        let oldSns = that.$store.getters.fetchSns(snsId)
                        if (oldSns) {
                            _sns = {...oldSns, ..._sns}
                        }
                        _sns.type = snsType
                        _sns.avatar = payload?.avatar
                        _sns.name = payload?.name
                        _sns.snsPwd = payload?.pwd
                        _sns.isOnline = isOnline
                        _sns.snsId = snsId
                        // that.upsertSns(_sns)
                        that.$store.commit('upsertSns', _sns)
                        // 绑定SNS
                        that.$store.commit('bindSnsId', {userId: that.uid, snsId}) //('bindSnsId', that.uid, that.snsId)
                    }
                } else if (13011 === type) { // 登录失败，密码错误的信息
                    const {snsId, snsType} = objData
                    const sns = that.$store.getters.fetchSns(snsId);
                    sns.isOnline = false
                    sns.snsPwd = ''
                    sns.pwd = ''
                    sns.type = snsType
                    // that.upsertSns(sns)
                    that.$store.commit('upsertSns', sns)
                    // 绑定SNS
                    that.$store.commit('bindSnsId', {userId: that.uid, snsId})
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    static msgListener(msg, view) {
        const that = view
        const MsgType = window.ginkgoTo.MsgType
        const {type, objData} = msg
        if (type && objData) {
            if (type === MsgType?.ON_MESSAGE.code) {
                const {snsId, snsType, message} = objData
                // console.log('收到消息： ', objData, snsId, message)
                if (message && message.payload) {
                    // const {roomId, talkerId, message_type, sub_type} = message.payload
                    const {wrapRoomId, wrapTalkerId, self, wrapListenerId, msgType, subType, sender} = message // 兼容wx-web
                    let _subjectId = (wrapRoomId || wrapTalkerId)
                    if (snsId === wrapTalkerId) {
                        // return // 不处理自己发的消息
                        console.log("cacheKey:::", wrapListenerId)
                        _subjectId = wrapListenerId || _subjectId
                    }
                    const cacheKey = 'msg_' + snsId + '_' + msgType + '_' + subType + '_' + _subjectId

                    that.pushMsgToHistory(cacheKey, message)

                    // 下面这段代码是处理好友列表还没有及时更新，而新的好友发消息来，则直接加入friends里
                    if (wrapListenerId && !wrapRoomId) { // 好友消息
                        const friendsKey = 'friends_' + snsId
                        let _friends = that.$dba.getItem(friendsKey, {})
                        if (!_friends[wrapTalkerId]) {
                            _friends[wrapTalkerId] = {
                                id: wrapTalkerId,
                                payload: {
                                    avatar: 'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + wrapTalkerId,
                                    friend: true,
                                    id: wrapTalkerId,
                                    name: sender?.nickname,
                                    gender: 0,
                                }
                            }
                            that.$dba.setItem(friendsKey, _friends)
                        }
                    }

                    // localStorage.setItem(cacheKey, JSON.stringify(_msgHistory))
                    // console.log("保存到数据库完成")
                    // let isTempMsg = false
                    if ('group' === subType && 'private' === msgType) { // 临时会话
                        console.log("临时回话。。。。")
                        // 如果是临时会话，在这里创建一个虚拟room，方便在初始化的时候统一载入消息列表
                        // TODO: 加好友时，需要将这个会话的历史合并
                        const tsKey = 'tempSessions_' + snsId
                        // const _tempSessionsStr =  // localStorage[tsKey]
                        let _tempSessions = that.$dba.getItem(tsKey, {})
                        // https://q1.qlogo.cn/g?b=qq&s=0&nk=2707302387
                        _tempSessions[wrapTalkerId] = {
                            id: wrapTalkerId,
                            payload: {...message.payload, avatar: 'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + wrapTalkerId}
                        }

                        that.$dba.setItem(tsKey, _tempSessions)
                        // isTempMsg = true
                        // localStorage.setItem(tsKey, JSON.stringify(_tempSessions))
                    }
                    // 自动回复处理逻辑，checkReplaySettings 检查用户设置，返回true则启用回复，false则不处理
                    const isSend = that.checkReplaySettings(snsId, message);
                    // console.log("是否发送到服务器--->", isSend)
                    if (isSend) {
                        let _msgType = '';
                        let text = '';
                        let reg = new RegExp("<br/>", "g")
                        if (snsType === 'OICQ' || snsType === 'MQ' || snsType === 'WXP') {
                            if (msgType === 'group') {
                                _msgType = 'SnsQQGroupMsg';
                            } else if (msgType === 'private') {
                                _msgType = 'SnsQQPersonalMsg';
                            }
                            text = message.payload?.text
                        } else if (snsType === 'WX_WEB') {
                            if (msgType === 'group') {
                                _msgType = 'SnsWXGroupMsg';
                            } else if (msgType === 'private') {
                                _msgType = 'SnsWXPersonalMsg';
                            }
                            if (message.payload?.text.indexOf("<br/>") !== -1) {
                                text = message.payload?.text.replace(reg, '\n');
                            } else {
                                text = message.payload?.text;
                            }
                        } else if (snsType === "EQQ") {
                            if (msgType === 'group') {
                                _msgType = 'SnsQQGroupMsg'
                            }
                            text = message.payload?.text
                        }
                        const ginkgoToNlpMsg = {
                            msgId: '',
                            fromSnsId: wrapTalkerId, // 没有看到groupId？？
                            fromSnsName: sender?.nickname,
                            // fromSnsFace: '',
                            toSnsId: msgType === 'group' ? wrapRoomId : snsId,
                            toSnsName: msgType === 'group' ? message?.payload?.group_name : message?.payload?.wrapTalkerId,
                            toSnsFace: '',
                            receiverSnsId: snsId,
                            receiverSnsName: that.getSns(snsId).name,
                            receiverSnsFace: that.getSns(snsId).avatar,
                            ginkgoUserId: that.uid,
                            ginkgoUserName: that.user?.userName,
                            ginkgoUserFace: that.user?.userImage,
                            content: text || message.payload?.raw_message,
                            msgType: _msgType,
                            receiveTimestamp: new Date().getTime(),
                            data: {tag: 'GTCS'},
                            self,
                        }
                        // 推送type = 0xF0001 的消息给netty服务器
                        if (window.ginkgoTo) {
                            let json = {
                                ginkgoUserId: that.uid,
                                type: 10101,
                                content: ginkgoToNlpMsg
                            }
                            window.ginkgoTo.invoke('postMessage', json)
                        }
                        console.log("信息发送给netty服务器")
                    } else {
                        console.log("自动回复设置返回为false，不处理信息")
                    }
                }
            } else if (type === 300000) {
                // TODO 这里处理第三方自动回复服务推送过来的消息（可以全部整合到这里，放弃上面的逻辑）
                console.log('This is the information pushed by the third party-->', msg)
                let arrays = msg.objData.to;
                const fromArray = msg.objData.from.userId.split('/');
                const snsId = fromArray[1];
                const snsInfo = that.getSns(snsId);
                let targetId;
                let groupId;
                let type;
                if (arrays.length === 2) {
                    arrays.forEach(item => {
                        if (item.type === 'PRIVATE') {
                            const idArr = item.userId.split('/');
                            targetId = idArr[1];
                        } else {
                            const idArr = item.userId.split('/');
                            groupId = idArr[1];
                        }
                    })
                } else if (arrays.length === 1) {
                    console.log("arrays:", arrays)
                    targetId = arrays[0].userId.split('/')[1];
                    type = arrays[0].type;
                    groupId = targetId;
                }
                console.log("snsInfo: ", snsId, snsInfo)
                if (snsInfo.type === 'EQQ' || snsInfo.type === 'OICQ' || snsInfo.type === 'MQ' || snsInfo.type === 'WXP') {
                    // console.log("自动回复", snsId, msg) 个Q和企Q没有报价提示
                    //TODO 暂时先按照目前的走，后续修改
                    const cacheKey = 'msg_' + snsId + '_private_friend_' + targetId
                    const _msg = {};
                    const _id = new Date().getTime() + ""
                    _msg.msgType = "private"
                    _msg.snsType = snsInfo.type
                    _msg.subType = "friend"
                    _msg.wrapTalkerId = snsId
                    _msg.id = _id
                    let _sender = {};
                    _sender.nickname = snsInfo.name
                    _sender.user_id = snsId
                    _msg.sender = _sender;
                    let _payload = {};
                    _payload.message_id = _id
                    let _message = [];
                    let _content = {};
                    _content.type = "text"
                    _content.text = msg.objData.content
                    _message.push(_content)
                    _payload.message = _message
                    _payload.post_type = "message"
                    _payload.user_id = snsId
                    _payload.from_id = snsId
                    _payload.listenerId = targetId
                    _payload.message_type = "private"
                    _payload.self_id = targetId
                    _payload.sub_type = 'friend'
                    _payload.talkerId = snsId
                    _payload.text = msg.objData.content
                    _payload.toId = targetId
                    _payload.type = 7
                    _payload.timestamp = new Date().getTime()
                    _msg.payload = _payload;
                    that.pushMsgToHistory(cacheKey, _msg)
                    console.log("cacheKey:", cacheKey, _msg)
                }

                if (snsInfo.type === 'EQQ') {//executePuppetApi
                    that.$gkt.executeSnsApi(that.uid, snsId, snsInfo.type, 'sendMessage', {
                        roomId: groupId,
                        contactId: targetId,
                        roomType: 'friend',
                        msg: msg.objData.content
                    })

                    console.log("发送给后台")
                } else if (snsInfo.type === "OICQ" || snsInfo.type === 'MQ') {
                    that.$gkt.executeSnsApi(that.uid, snsId, snsInfo.type, 'sendMessage', {
                        roomId: groupId,
                        contactId: targetId,
                        roomType: type === 'PRIVATE' ? 'friend' : 'temp',
                        msg: msg.objData.content
                    })
                } else if (snsInfo.type === 'WXP') {
                    that.$gkt.executeSnsApi(that.uid, snsId, snsInfo.type, 'sendMessage', {
                        contactId: targetId,
                        msg: msg.objData.content,
                        roomType: 'friend',
                        roomId: groupId
                    })
                } else {
                    that.$gkt.executeSnsApi(that.uid, snsId, snsInfo.type, 'sendMessage', {
                        roomId: groupId,
                        contactId: targetId,
                        msg: msg.objData.content
                    })
                }
            }
        }
    }

    static gzhAutoReply(msg, view) {
        const that = view
        const {objData, type} = msg
        // console.log('收到公众号客户消息debug：', type)
        if (type === 620002) {
            console.log('收到公众号自动回复给客户消息：', objData)
            if (objData && objData.content && objData.snsId) {
                const {touser} = objData.content
                if (touser) {
                    const pKey = 'gzh_reply_' + objData.snsId + '_' + touser
                    const gzhReplyHistory = that.$dba.getItem(pKey, [])
                    gzhReplyHistory.push(objData)
                    that.$dba.setItem(pKey, gzhReplyHistory)
                }
            }
        }
    }

    static checkSnsOnlineStatus(msg, view) {
        // const that = view
        const {objData, type} = msg
        if (type === window.ginkgoTo.MsgType['ON_ONLINE_STATUS'].code) {
            let oldSns = view.getSns(objData.snsId)
            if (oldSns) {
                // oldSns.isOnline = objData?.isOnline
                console.log(`SNS在线状态检查：${oldSns?.isOnline}/${objData?.isOnline}/${oldSns.snsType}`)
                view.upsertSns({...oldSns, isOnline: objData?.isOnline})

                if (!objData?.isOnline) {
                    if ('WX_GZH' === oldSns.snsType) {
                        console.log('准备调用login方法...', oldSns)
                        view.$gkt.executeSnsApi(view.uid, oldSns.snsId, 'WX_GZH', 'login', {
                            token: {
                                kfId: oldSns.snsId, // 客服
                                uid: view.uid,
                                gzhId: oldSns.payload?.gzhId
                            }
                        })
                    } else if ('CHAT_GPT' === oldSns.snsType) {
                        view.$gkt.executeSnsApi(view.uid, view.uid, 'CHAT_GPT', 'login', {
                            snsId: view.uid,
                            uid: view.uid,
                            user: {
                                name: view.user.userName,
                                snsId: view.uid,
                                avatar: view.user.userImage
                            }
                        })
                    }
                    // this.gktExecuteSnsApi({uid: this.uid, method: 'login', snsType: 'OICQ', snsId: this.snsId, args: {}})
                    // this.$gkt.executeSnsApi(this.uid, this.snsId, 'WX_GZH', 'login', {
                    //   token: {
                    //     kfId: this.customId, // 客服
                    //     uid: this.uid,
                    //     gzhId: this.gzhId
                    //   }
                    // })
                    // this.gktExecuteSnsApi({
                    //   uid: this.uid,
                    //   method: 'login',
                    //   snsType: 'WX_WEB',
                    //   snsId: this.snsId,
                    //   args: {wxName: this.wxName}
                    // })
                }

            }


        }
    }

    static groupSelector(msg, view) {
        const {type, objData} = msg

        if (type === 16003) {
            if (objData.flag === 'GROUP') {
                if (null !== objData.data) {
                    view.groupIds = objData.data;
                } else {
                    view.groupIds = [];
                }
                // console.log("groupIds:::", this.groupIds)
                const gKey = 'groups_' + view.$root.uid + '_' + view.sns.snsId;
                view.$dba.setItem(gKey, view.groupIds)
            } else {
                if (null !== objData.data) {
                    view.friendIds = objData.data;
                } else {
                    view.friendIds = [];
                }
                const gKey = 'friends_' + view.$root.uid + '_' + view.sns.snsId;
                view.$dba.setItem(gKey, view.friendIds)
            }
        }

    }
}

module.exports = SnsMsgHandler
