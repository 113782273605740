<template>
  <!-- 充值对话框 -->
  <div class="modal " tabindex="-1" role="dialog" id="mdlPay">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content bg-dark border-0">
        <div class="modal-header border-0 d-flex align-items-center">
          <h2 class="h4 text-light mb-0"><i class="fi-cart text-primary fs-5 mt-n1 me-2"></i>充值</h2>
          <button class="btn btn-sm btn-icon" type="button" data-bs-dismiss="modal" aria-label="Close">
            <i class="fi-x"></i>
          </button>
        </div>
        <div class="modal-body border-0">
          <!-- Light default pricing plan -->
          <div class="card card-light border-light">
            <div class="card-body">
              <div class="card card-light card-horizontal border-0 bg-none">
                <div class="card-img-top"
                     style="background: url('https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/auto-shop.jpeg') no-repeat center; background-size: contain;"></div>
                <div class="card-body">
                  <h2 class="h5 fw-normal text-light text-center py-1 mb-1">扫码进商城购买</h2>
                  <div class="d-flex align-items-end justify-content-center mb-4">
                    <div class="h1 text-light mb-0">￥20 ~ ￥200</div>
                  </div>
                  <ul class="list-unstyled d-block mb-0 mx-auto " style="max-width: 18rem;">
                    <li class="d-flex">
                      <i class="fi-check text-primary fs-sm mt-1 me-2"></i>
                      <span class="text-light">￥20</span><span
                        class="text-light opacity-70 pb-2 ps-2">/ 2000 应用点，赠88点</span>
                    </li>
                    <li class="d-flex">
                      <i class="fi-check text-primary fs-sm mt-1 me-2"></i>
                      <span class="text-light">￥50</span><span
                        class="text-light opacity-70 pb-2 ps-2">/ 5000 应用点，赠288点</span>
                    </li>
                    <li class="d-flex text-light">
                      <i class="fi-check text-primary fs-sm mt-1 me-2"></i>
                      <span class="text-light">￥100</span><span class="text-light opacity-70 pb-2 ps-2">/ 10000 应用点，赠888点</span>
                    </li>
                    <li class="d-flex text-light">
                      <i class="fi-check text-primary fs-sm mt-1 me-2"></i>
                      <span class="text-light">￥200</span><span class="text-light opacity-70 pb-2 ps-2">/ 20000 应用点，赠2888点</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-footer py-2 border-0">
              <div class="border-top border-light text-center pt-4 pb-3">
                <a href="javascript:;" class="btn btn-outline-light btn-sm rounded-pill" data-bs-dismiss="modal">确定</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 兑换码 -->
  <div id="mdlPayCode" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content bg-dark border-0">
        <!-- a -->
        <div class="modal-header border-light">
          <h5 class="modal-title text-light">绑定兑换码</h5>
          <button type="button" class="btn-close btn-light" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label text-light">兑换码</label>
            <input class="form-control form-control-light" type="text" placeholder="请输入兑换码" v-model="code">
            <label class="form-label text-light my-2">扫码联系客服获取兑换码</label>
            <div class="card card-light text-center">
              <div class="card-body">
                <img class="rounded" style="height: 18rem;"
                     :src="'//zhycit-static.oss-cn-beijing.aliyuncs.com/ui/wx1.jpg'">
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-light">
          <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">取消</button>
          <button type="button" class="btn btn-primary btn-sm" @click="bindingCode">立即绑定</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Sns登录对话框 -->
  <!-- 登录用的模态框 -->
  <template v-for="sns in $root.bindSnsList" v-bind:key="sns.snsId">
    <div :id="'mdl_'+sns.snsId?.replace('@', '')" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content border-0 bg-dark">
          <div class="modal-header p-1 border-0 d-flex justify-content-end">
            <button class="btn btn-sm btn-icon" type="button" data-bs-dismiss="modal" aria-label="Close">
              <i class="fi-x"></i>
            </button>
          </div>
          <div class="modal-body">
            <component :is="getSnsLoginComponent(sns.type)" :uid="$root.uid" :sns="sns"></component>
          </div>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <GroupModal :sns="sns" :show="showModal" @close="showModal = false"></GroupModal>
    </Teleport>
    <Teleport to="body">
      <FriendModal :sns="sns" :showFriend="showFriendModal" @closeFriend="showFriendModal = false"></FriendModal>
    </Teleport>

  </template>

</template>

<script>
import {ElMessage, ElMessageBox} from "element-plus";
import MqLogin from "@/components/sns/MqLogin";
import WxWebLogin from "@/components/sns/WxWebLogin";
import WxpLogin from "@/components/sns/WxpLogin";
import EqqLogin from "@/components/sns/EqqLogin";
import GzhLogin from "@/components/sns/GzhLogin";
import ChatGptLogin from "@/components/sns/ChatGptLogin";
import GroupModal from "@/components/sns/models/GroupModal";
import FriendModal from "@/components/sns/models/FriendModal";

export default {
  name: 'GlobalModals',
  components: {
    EqqLogin,
    WxWebLogin,
    WxpLogin,
    MqLogin,
    GzhLogin,
    ChatGptLogin,
    GroupModal,
    FriendModal
  },
  data: () => {
    return {
      code: '',
      showModal: false,
      showFriendModal: false,
      showNotice: true,
      showStartTip: false,
    }
  },
  mounted() {
    const showNotice = this.$store.getters.dontShowNoticeAgain()
    console.log('showNotice-->', showNotice)
    /*const gpt_uid = this.$dba.getItem('gptUid')
    if (gpt_uid){

    }*/
    if (!showNotice && this.$root.uid) {
      // this.showMessageBox()
    }
  },
  methods: {
    //绑定兑换码
    bindingCode() {
      const uid = this.$store.getters.currentUid()
      if (uid) {
        window.ginkgoTo.bindCodeKey(this.code, uid).then(result => {
          const {status, msg} = result
          window.parent.$('#mdlPayCode').modal('hide')
          if (status === 0) {
            this.isBind = true;
            return ElMessage({
              message: msg, type: 'success'
            });
          } else {
            this.isBind = false;
            return ElMessage({
              message: msg, type: 'error'
            });
          }
        })
      } else {
        this.$router.push({name: 'login'})
      }

    },
    getSnsLoginComponent(snsType) {
      const desc = window.ginkgoTo?.SnsDesc[snsType]
      // console.log('desc????----', desc?.loginComponent || 'OicqLogin')
      return desc?.loginComponent || 'OicqLogin'
    },
    onStartTipConfirmed() {
      this.$store.commit('setDontShowNoticeAgain', true)
    },
    showMessageBox() {
      // this
      ElMessageBox.alert('<div class="text-start mb-2"><b>尊敬的用户:</b></div>' +
          '<div class="text-start font-monospace text-black-50 mb-3 small">' +
          '<p>　　感谢您一直以来对该程序的支持与使用,为了提供更好的服务体验，我们决定进行企业QQ协议的更新。' +
          '我们非常抱歉地通知您，由于更新工作的复杂性和涉及的范围较大，' +
          '修复时间暂时还无法确定。我们正在全力以赴进行开发和测试工作，以确保新协议的稳定和可靠。</p>' +
          '<p>　　我们理解这可能给您带来一些不便，我们深感抱歉。我们将尽最大努力，尽快完成修复工作，并将在更新完成后及时通知您。</p>' +
          '<p>　　在此期间，您可能无法通过企业QQ发送和接收消息等功能。我们深表歉意给您带来的不便和困扰。为了您的沟通需求，我们建议您可以尝试使用其他即时通讯工具，如微信等。</p>' +
          '<p>　　如有任何疑问或需要进一步帮助，请随时联系我们的客服团队，我们将尽力为您解答并提供帮助。\n' +
          '再次感谢您的支持与理解！</p></div>',
          '公告', {
            // if you want to disable its autofocus
            // autofocus: false,
            center: true,
            type: 'warning',
            dangerouslyUseHTMLString: true,
            confirmButtonText: '关闭',
          }).then(() => {
        this.onStartTipConfirmed()
      })
    }
  }
}
</script>
